// fonts
.font-montserrat {
  font-family: "avenir", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.font-montserrat-300 {
  font-family: "montserrat-light", "avenir", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 300;
}

.font-montserrat-400 {
  font-family: "montserrat-regular", "avenir", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
}

.font-montserrat-500 {
  font-family: "montserrat-medium", "avenir", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500 !important;
}

.font-montserrat-700 {
  font-family: "montserrat-bold", "avenir", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
}

.font-orbitron-500 {
  font-family: "orbitron-medium", "avenir", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif !important;
  font-weight: 500 !important;
}

// common
.display-none {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
}

.display-hide {
  pointer-events: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

// animations
.animation-fade-in {
  opacity: 0;
  animation: fade 0.2s ease 0.1s forwards;
}

// body::-webkit-scrollbar {
//   width: 5px;
//   height: 5px;
// }

// /* Handle */
// body::-webkit-scrollbar-thumb {
//   /* background: var(--primary); */
//   /* background: #555; */
//   background: transparent;
//   border-radius: 30px;
// }

// /* Handle on hover */
// body::-webkit-scrollbar-thumb:hover {
//   background: #555;
// }

// .body-scrollbar-visible {
// }

// .body-scrollbar-visible::-webkit-scrollbar-thumb {
//   /* background: var(--background-primary-light) !important; */
//   /* background: rgba(246, 95, 31, 0.5); */
//   background: #555;
// }

// buttons
.button-disabled {
  transition: all 0.2s ease;
  opacity: 0.8 !important;
  pointer-events: none !important;
  cursor: no-drop;
}

// text colors

.text-primary-main {
  color: var(--primary);
}

.text-secondary-main {
  color: var(--secondary);
}

// Classes for skeleton
.loading-skeleton {
  width: 100%;
}
