.mobile-verification {
  position: relative;
  font-family: var(--font-montserrat);
  text-align: start;

  .background-image-container-mobile {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -2;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .background-layer-mobile {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
    backdrop-filter: blur(2px);
  }
  .mobile-header-container {
    padding: 30px 50px;
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;

    @media (max-width: 600px) {
      padding: 30px 20px;
    }
  }

  .footer-container-mobile {
    bottom: 0;
    position: fixed;
    padding: 30px 50px;

    .footer-details {
      display: flex;
      color: var(--text-white);
    }

    .verrion-stack-info {
      display: flex;
      align-items: center;
      span {
        font-size: 14px;
        margin-left: 10px;
      }
    }

    .light-theme-info {
      display: flex;
      align-items: center;
      margin-left: 20px;
      span {
        font-size: 14px;
        margin-left: 10px;
      }
    }

    .color-pallete-icon {
      path {
        stroke: unset !important;
      }
    }

    .version-stack-icon {
      path {
        stroke: var(--icon-white) !important;
      }
    }

    @media (max-width: 600px) {
      width: 100%;
      padding: 30px 20px;
      .footer-details {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
  .two-factor-text {
    color: white;
    font-size: 1.3rem;
    font-family: var(--font-montserrat);
    padding-top: 200px;
    display: flex;
    justify-content: center;
  }
  .text-factor {
    color: white;
    font-size: 0.9rem;
    // width: 481px;
    // height: ;
    text-align: center;
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }
  .otp-group {
    display: flex;
    width: 100%;
    max-width: 360px;
    column-gap: 15px;
    background-color: none !important;
    padding-left: 50px;
  }
  .otp {
    display: flex;
    justify-content: center;
    padding: 20px;
    // .MuiInputBase-input{
    //   padding: 4px 9px 7px !important;
    // }
  }
  .otp-input {
    width: 33px;
    height: 32px;
    border: 1px solid #ccc;
    text-align: center;
    font-weight: bold;
    color: white;
    background-color: transparent;
  }
  .button-verify {
    display: flex;
    justify-content: center;
  }
  .submit-button {
    width: 285px;
    height: 46px;
    display: flex;
    cursor: pointer;
    margin-top: 40px;
    // border-radius: 30px;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--border-white);
    transition: all 0.2s ease-in-out;
    background-color: transparent;
    .loader-container {
      display: flex;
      margin-right: 20px;
      svg {
        color: var(--loader-white);
        circle {
          stroke: var(--loader-white) !important;
        }
      }
    }
    .login-text {
      font-size: 17px;
      color: var(--text-white) !important;
    }
    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }
  .this-code {
    color: white;
    display: flex;
    justify-content: center;
    padding-top: 10px;
    font-family: var(--font-montserrat);
    font-weight: bold;
    text-decoration: underline;
    font-size: 1.1rem;
    cursor: pointer;
  }
  .back-login {
    color: white;
    display: flex;
    justify-content: center;
    padding-top: 15px;
    font-family: var(--font-montserrat);
    // font-weight: bold;
    text-decoration: underline;
    font-size: 1.1rem;
    cursor: pointer;
  }
  .time-verify {
    color: white;
    display: flex;
    justify-content: center;
    // padding-top: 15px;
    // font-family: "Montserrat-Bold";
    // text-decoration: underline;
    font-size: 0.8rem;
  }
}
.MuiInputBase-root {
  // height: 30.47px !important;
  //   width: 30.47px !important;
  //   border: 1px solid #fffefe;
  //   background: transparent;
  color: white !important;
}

a {
  color: white;
}
a:hover {
  color: white;
}
.resend-text {
  display: flex;
  justify-content: center;
  // padding-left: 25px;
  // padding-right: 25px;
  padding-top: 10px;
  color: white;
  // font-size: 0.9rem;
  gap: 10px;
}
