@import "../styles/colors.scss";

.login-screen {
  position: relative;
  font-family: var(--font-montserrat);
  text-align: start;

  .content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .background-image-container {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    z-index: -2;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .background-layer {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
    // backdrop-filter: blur(2px);
  }

  .login-header-container {
    padding: 30px 50px;
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;

    @media (max-width: 600px) {
      padding: 30px 20px;
    }
  }

  .logo-container {
    align-items: baseline;
    cursor: pointer;
    width: fit-content;

    .ticca-logo-text-white {
      path {
        stroke: none !important;
      }
    }

    .ticca-logo-icon-white {
      path {
        stroke: none !important;
      }
    }

    @media (max-width: 600px) {
      width: 100%;
      display: flex;
      justify-content: center;

      .ticca-logo-text-white {
        width: 120px;
      }

      .ticca-logo-icon-white {
        width: 25px;
      }
    }

    .logo-icon {
      opacity: 0;
      // display: none;
      visibility: hidden;
      transform: translateX(-20px);
      animation: nav-tab-name-slide-in 0.2s ease 0.2s forwards;
    }

    .logo-title {
      opacity: 0;
      // display: none;
      visibility: hidden;
      transform: translateX(-20px);
      animation: nav-tab-name-slide-in 0.2s ease 0.25s forwards;
    }

    .logo-name-icon {
      margin-left: 7px;
      display: none;
    }

    // margin-top: 18px;
    .ticca-logo-icon {
      width: 25px;
      path {
        stroke: none !important;
      }
    }

    .ticca-logo-name {
      width: 125px;
      path {
        stroke: none !important;
      }
    }

    .logo-link-container {
      display: flex;
      align-items: flex-end;
      position: relative;
    }

    .logo-title {
      margin-bottom: 2px;
      // position: absolute;
      // bottom: 0px;
      // left: 40px;
      margin-left: 8px;
    }
  }

  .form-container {
    // max-width: 286px;
    margin: auto;
  }

  .errors-container {
    width: 100%;
    max-width: 388px;
    margin-top: 20px;
    @media (max-width: 600px) {
      width: 100%;
      max-width: unset;
    }
    .message-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: var(--error-primary);
      border-radius: 10px;
      padding: 14px 20px;
      padding-right: 15px;
      opacity: 0;
      transform: translate(0px, 5px);
      animation: bottom-in 0.2s ease forwards;
      margin: 10px 0px;
      p {
        color: white;
        padding-right: 10px;
        margin: 0px;
        font-size: 14px;
        letter-spacing: 0.02em;
        line-height: 130%;
      }
      .close-icon {
        display: flex;
        min-width: 20px;
        align-items: center;
        justify-content: center;
        align-self: flex-start;
        svg {
          width: 15px;
          height: 15px;
          fill: var(--icon-white);
          path {
            stroke: none !important;
          }
        }
      }
    }
    @media (max-width: 900px) {
      width: 100%;
      .message_container {
        font-size: 14px;
        padding: 15px 15px;
      }
      .close_icon {
        min-width: 30px;
      }
    }
  }

  .login-input {
    input {
      //   font-weight: 300;

      box-sizing: border-box;
      width: 220px;
      margin-left: 5px !important;
      border-radius: 0px;
      padding-inline-start: 10px;
      padding-inline-end: 10px;
      box-shadow: none !important;
      // color: var(--text-white) !important;
      font-family: var(--font-montserrat);
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        opacity: 1 !important;
        color: var(--text-white) !important;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        opacity: 1 !important;
        color: var(--text-white);
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        opacity: 1 !important;
        color: var(--text-white);
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        opacity: 1 !important;
        color: var(--text-white) !important;
      }
      &:-webkit-autofill {
        // background: black !important;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        // background: black !important;
        -webkit-box-shadow: 0 0 0px 50px rgba(0, 0, 0, 0) inset !important;
        // -webkit-text-fill-color: yellow !important;
      }
    }
    color: var(--text-white);

    svg {
      path {
        stroke: var(--icon-white) !important;
      }
    }

    .close-eye-icon {
      path {
        stroke: none !important;
        fill: var(--icon-white);
      }

      .close-eye-line {
        stroke: var(--icon-white) !important;
      }
    }

    .open-eye-icon {
      path {
        stroke: none !important;
        fill: var(--icon-white);
      }
    }

    fieldset {
      border: none;
      border-radius: 0px;
      border-bottom: 1px solid var(--border-white);
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      fieldset {
        border-color: var(--primary) !important;
      }
    }
  }

  .password-input {
    margin-top: 5px;
  }

  .forgot-text {
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    // display: block;
    color: white !important;
    margin-top: 15px;
    text-decoration: none;
    &:hover {
      color: var(--primary);
    }
  }

  .submit-button {
    width: 100%;
    height: 46px;
    display: flex;
    cursor: pointer;
    margin-top: 38px;
    border-radius: 3px;
    align-items: center;
    justify-content: center;
    background: none;
    border: 1px solid var(--border-white);
    transition: all 0.2s ease-in-out;
    .loader-container {
      display: flex;
      margin-right: 20px;
      svg {
        color: var(--loader-white);
        circle {
          stroke: var(--loader-white) !important;
        }
      }
    }
    .login-text {
      font-size: 17px;
      color: white !important;
    }
    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  .already-have-account-text {
    font-size: 14px;
    margin-top: 15px;
    text-align: center;
    color: white;
    .link {
      text-decoration: none;
      color: white !important;
    }
  }

  .footer-container {
    bottom: 0px;
    position: fixed;
    padding: 30px 50px;

    .footer-details {
      display: flex;
      color: var(--text-white);
    }

    .verrion-stack-info {
      display: flex;
      align-items: center;
      span {
        font-size: 14px;
        margin-left: 10px;
      }
    }

    .light-theme-info {
      display: flex;
      align-items: center;
      margin-left: 20px;
      span {
        font-size: 14px;
        margin-left: 10px;
      }
    }

    .color-pallete-icon {
      path {
        stroke: unset !important;
      }
    }

    .version-stack-icon {
      path {
        stroke: var(--icon-white) !important;
      }
    }

    @media (max-width: 600px) {
      width: 100%;
      padding: 30px 20px;
      .footer-details {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }

  .page-content {
    opacity: 0;
    width: 100%;
    display: none;
    visibility: hidden;
    transform: translateY(50px);
    // transition: all 0.2s ease;
    animation: main-content-slide-up 0.5s ease 1s forwards;
  }
}
// .MuiInputBase-input{
//   background-color: none !important;
// }

.show-login-page-content {
  visibility: visible !important;
  display: block !important;
}

@keyframes nav-tab-name-slide-in {
  to {
    opacity: 1;
    visibility: visible;
    transform: translateX(0px);
  }
}

@keyframes bottom-in {
  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes main-content-slide-up {
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}
.verification {
  display: flex;
  gap: 5px;
}
.MuiTypography-root {
  color: var(--text-white);
  // font-family: var(--font-montserrat) !important;
  font-size: 0.8rem !important;
}
