@import url("./styles.scss");
.toast-container {
  min-width: 456px;
  // margin-left: -150px;
  // background-color: rgb(0, 0, 0);
  min-height: 67px;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 16px;
  bottom: -3rem;
  right: 9.7rem;
  font-size: 17px;
  margin-top: -20px;
  position: relative;

  // Responsive adjustments for tablet
  @media (max-width: 768px) {
    min-width: 400px;
    // Reduce min-width for smaller screens
    font-size: 14px !important; // Reduce font size slightly
  }

  // Responsive adjustments for mobile
  @media (max-width: 480px) {
    max-width: 400px; // Further reduce for mobile
    font-size: 12px !important; // Adjust font size for mobile
    left: 15px;
    bottom: 30px;
  }

  // Responsive adjustments for mobile
  @media (max-width: 390px) {
    max-width: 320px; // Further reduce for mobile
    font-size: 12px !important; // Adjust font size for mobile
    left: 15px;
    bottom: 30px;
  }
}
.toast-container::before {
  content: "";
  position: absolute;
  width: 8px;
  top: 0px;
  left: 0px;
  height: 100%;
}
.Toastify__toast-body > div:last-child {
  color: #000;
}
.success-message {
  border: 1px solid rgba(89, 158, 37, 0.4) !important;

  &::before {
    background: var(--success-toast-color);
  }

  svg {
    background: var(--success-toast-color);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
}

.error-message {
  border: 1px solid rgba(61, 25, 25, 0.4);

  &::before {
    background: var(--error-toast-color);
  }
}

.warning-message {
  border: 1px solid rgba(164, 115, 0, 0.4);

  &::before {
    background: var(--warning-toast-color);
  }
}

.toast-container > div {
  font-family: "montserrat-regular", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif !important;
  font-weight: 400;
  font-size: 14px;
  text-align: start;
  line-height: 120%;
  word-wrap: break-word; /* For long words */
  overflow-wrap: break-word; /* Ensures words break when needed */
  word-break: break-all; /* Forces breaks within long words */
}

.toast-container svg path {
  stroke: none !important;
  height: 31px !important;
  width: 31px !important;
}

.Toastify__toast-body {
  display: flex;
  align-items: flex-start !important;
}

.Toastify__toast-icon {
  margin-inline-start: 15px;

  .format-icon {
    path {
      fill: none !important;
      stroke: var(--left-side-bar-menu-icons-color) !important;
    }
  }
}
.Toastify__toast-container--bottom-right {
  bottom: 4em !important;
  right: 1em;
}

// .Toastify__toast-body > div:last-child::before {
//   content: "";
//   background: url("../../public/logo192.png") no-repeat center center/cover;
//   position: absolute;
//   width: 24px;
//   height: 24px;
//   left: 34px;
// }
// .menu-container {
//   /* Existing CSS styles */
//   background-color: var(--left-side-bar-menu-poupup-bg-color) !important;
//   /* Rest of the styles */
// }
