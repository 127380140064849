.error-icon-container {
  width: 75px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid var(--primary);
  border-radius: 50% !important;
  .error-icon {
    width: 6px;
    height: 30px;
  }
}
.set-widget-icon{
  path{
    // fill : var(--widget-window_header_icon-color),
  }
  path{
    stroke : var(--widget-window_header_icon-color),
  }
}
.minimize-icon-container {
  width: 13px;
  height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  .windows-minimize-icon {
    // width: ;
    width: 13px;
    height: 1px;
    display: block;
    background: var(--widget-window_header_icon-color);
  }
}

.maximize-icon-container {
  .maximize-icon {
    min-width: 13px;
    height: 13px;
    border: 1px solid var(--widget-window_header_icon-color);
    background: transparent;
    border-radius: 2px;
  }
}

.windows-close-icon {
  .icon-container {
    .close-line {
      width: 15px;
      height: 1px;
      background: var(--widget-window_header_icon-color);
    }

    .close-line-1 {
      transform: rotate(45deg) translate(0px, 1px);
      transform-origin: center;
    }

    .close-line-2 {
      transform: rotate(-45deg) translate(-0.5px, -1px);
      transform-origin: center;
    }
  }
}

.restore-minimize-icon-container {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  .line {
    width: 15px;
    height: 1px;
    display: block;
    background: var(--widget-window_header_icon-color);
  }
  .line-1 {
    width: 1px;
    height: 13px;
    transform: translateX(7.5px);
  }
}
