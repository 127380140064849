:root {
  // color variables
  // --left-side-bar-menu-poupup-bg-color: #043087;
  --primary: #0e59da;
  --secondary: #f65f1f;
  --primary-light: #e6eeff;
  --background: #ffffff;
  --background-primary: #ffffff;
  --text-primary: var(--primary);
  --background-primary-light: #e6eeff;
  --icon-hover-primary: #ffffff;
  --icon-white: #ffffff;
  --border-white: #ffffff;
  --text-white: #ffffff;

  // header variables
  // -- colors
  --header-bg: #ffffff;

  //toast
  --success-toast-color: linear-gradient(#98ef57, #599e25, #2e6703);
  --warning-toast-color: linear-gradient(#ffb200, #a47300, #654700);
  --error-toast-color: linear-gradient(#b71c27, #81141c, #5c0e14);
  --info-toast-color: linear-gradient(#2174ff, #1054c5, #003690);

  // ticca logo
  --logo-primary: #0e59da;
  --logo-seconday: #f65f1f;

  --dropdown-bg-primary: #181b1f;

  --text-primary-dark: var(--primary);
  --text-seconday-dark: var(--secondary);

  --windows-icon-primary: #000000;

  --loader-white: #ffffff;
  --loader-primary: #0e59da;
  --loader-secondary-light: rgba(255, 255, 255, 0.5);

  // --error-primary: #FF5C00;
  --error-primary: #ff3636;
  // --error-primary: red;

  --skeleton-bg-primary: #d4d4d41c;

  // Menu sepertors
  --menu-seperator-primary: rgba(255, 255, 255, 0.1);
}
[data-theme="dark"] {
  --primary: #141d24;
  --text-white: #ffffff;
  --left-side-bar-backgorund: #181b20;
  --sidebar-back-icon: #21242b;

  --left-side-bar-menu-link-text-hover-color: #22252c;
  --left-side-bar-menu-poupup-bg-color: #1e2127;
  --header-color: white;
  --back-button-icon-hove: #2c2e32;
  --free-style-icon-color: #fff;
  --calender-icon: white;
  --widget-left-menu-bg-color: black;
  --project-detail-progress-bar: linear-gradient(
    to Left,
    #2174ff,
    #135ad0,
    #003690
  );
  --project-detail-cirlce: linear-gradient(to top, #9e1efc, #4c2dc5, #063a97);
  --progressbar-color-bg: #707070;
  --border-color: #3b3d40;
  --profile-widget-hover-color: #23272c;
  --profile-button: #b9b9b9;
  --profile-widget-card-color: #23272d;
  --profile-widget-circle-color: #292c31;
  --background-color-card: #1e2126;
  --notification-tab-color-dark: #181b20;
  --notification-tab-color-light: #2c2f38;
  --assign-member-popup: #181b1f;
  --assign-member-popup-border-color: #2a2d30;
  --top-menu-lable-bg: #22252b;
  --copy-button-bg: rgb(78, 78, 78);
  --link-icon: white;
  --setting-switch-button-off: white;
  --setting-switch-button-on: #3d71d9;
  --setting-lable-dot-color: black;
  --setting-content-color: #22252c;
  --assign-member-lable: #181b1f;
  --card-background-color: #1e2126;
  --team-view-card-bg-color: #25282d;

  --team-view-card-border-color: #292b2f;
  --iconbg-color-view-team: #363a40;

  --delete-popup-color: #181b1f;

  --autoComplete-color: rgb(238, 238, 238);
  --autoComplete-color-border: rgb(238, 238, 238);
  --autoComplete-bg-color: #363a45;
  // scrollbar
  --scrollbar-thumb-color: rgba(255, 255, 255, 0.15);
  --project-add-button: #3d71d9;

  // Font family
  --font-montserrat: "Montserrat", "Avenir", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;

  --font-montserrat-300: "Montserrat", "Avenir", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  --font-montserrat-400: "Montserrat", "Avenir", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  --font-montserrat-500: "Montserrat", "Avenir", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  --font-montserrat-700: "Montserrat", "Avenir", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  // login
  --device-info-not-found-modal__header-border-color: linear-gradient(
    to right,
    #ffb200,
    #654700
  );
  --device-info-not-found-modal__content-background-color: #161c26;
  --device-info-not-found-modal__content-text-color-primary: #ffffff;
  --device-info-not-found-modal__content-text-color-secondary: #2072fb;

  // header variables
  // -- colors
  --header-bg: #141d24;

  --loader-white: #ffffff;
  --loader-primary: #0e59da;
  --loader-secondary-light: rgba(255, 255, 255, 0.5);

  //toast
  --success-toast-color: linear-gradient(#98ef57, #599e25, #2e6703);
  --warning-toast-color: linear-gradient(#ffb200, #a47300, #654700);
  --error-toast-color: linear-gradient(#b71c27, #81141c, #5c0e14);
  --info-toast-color: linear-gradient(#2174ff, #1054c5, #003690);

  // ticca logo
  --logo-primary: #ffffff;
  --logo-seconday: #f65f1f;

  // Main header
  --header__bg-color: #181b1f;
  --header__right-navigation-icons-color: #ffffff;

  // Left side bar
  --left-side-bar-bg-color: linear-gradient(#141d24, #141e27 30%, #172129 100%);
  --left-side-bar-menu-link-text-color: #ffffff;

  // Left menusidebar icons
  --left-side-bar-menu-icons-color: #ffffff;

  // Left menusidebar links

  --left-side-bar__menu-link__bg-color__hover: rgba(255, 255, 255, 0.14);

  // Left side bar toggle button
  --left-side-bar-toggle-button__bg-color: #202d35;
  --left-side-bar-toggle-button__caret-icon-color: #ffffff;

  // Left side bar link menu items container
  // Note:
  // search for the class "menu-container" and do the styling fix for this classes
  --lsb__link-menu-items-container__bg-color: #353e46;
  --lsb__link-menu-items-container__menu-item-color: #ffffff;

  // main page content

  --page-content__container_bg-color: #181b1f;

  // widget window
  --widget-bg-primary: #181b1f;
  --widget-window_header-bg-color: #1e2734;
  --widget-window_header-title-color: #ffffff;
  --widget-window_header-menu-burger-icon-color: #ffffff;
  --widget-window_header_icon-color: #ffffff;
  --widget-window_content-bg-color: #1e2734;
  --widget-window-box-shadow-color: rgba(0, 0, 0, 0.16);
  --widget-window-action-buttons-hover-color: rgba(255, 255, 255, 0.1);
  --widget-window-action-button-close-hover-color: rgba(183, 28, 39, 0.7);

  // Widgets

  // Timetracker
  --timetrack_rim-outer_bg-color: #ffffff;
  --timetrack_rim-inner_bg-color-stop: #aa1d29;
  --timetrack_rim-inner_bg-color-running: #4baa03;
  --timetrack_rim-inner_bg-color-idle: #f7ac29;
  --timetrack_rim-inner_bg-color-offline: #fc5426;

  // Timetrack widget header icons
  --timetrack-widget_header-icon-color: #ddd;

  // Timetracker menu
  --timetrack_left-side-menu_bg-color: #000000;
  --timetrack_left-side-menu_bg-color-hover: rgba(123 137 157 / 10%);
  --timetrack_menu-items-container-color: white;
  --timetrack_left-side-menu-items_text-color: rgb(30, 39, 52);
  --timetrack_left-side-menu-items_devider-color: rgba(255, 255, 255, 0.5);

  --autocomplete-selector_border-color: #dddddd;
  --autocomplete-selector_lable-color: #ffffff;
  --autocomplete-selector_lable-bg-color: #181b1f;
  --autocomplete-selector_lable-bg-profile-color: #1e2126;

  --autocomplete-selector_ipnut-text-color: #ffffff;
  --autocomplete-selector_dropdown-bg-color: #ffffff;
  --autocomplete-selector_dropdown-caret-icon-color: #ffffff;
  --autocomplete-selector_dropdown-option-color: #1e2734;

  --dropdown-bg-primary: #181b1f;
  --dropdown-bg-primary-dark: #363a45;

  // Project widget
  --project-widget_input-border-color: #dddddd;
  --project-widget_input-background-color: #1e2734;
  --project-widget_input-lable-color: #ffffff;
  --project-widget_input-text-color: #ffffff;
  --project-widget_input-lable-bg-color: #181b1f;

  //Project List widget

  --projectlist-input-border-color: white;
  --projectlist-widget_input-border-color: rgb(93, 93, 93);
  --projectlist-widget-background-color: #1e2734;
  --projectlist-widget-text-color: #ffffff;
  --projectlist-widget-text-label-color: #ffffff;
  --projectlist-widget-button-color: rgb(93, 93, 93);

  // project list table
  --project-list-table-header-text-color: #ffffff;
  --project-list-table-bg-color: #1e2734;
  --project-list-table-data-text-color: #ffffff;
  --project-list-table-progress-color: rgba(255, 255, 255, 0.3);
  --project-list-table-progress-percents-color: #ffffff;
  --project-list-table-progress-bar-color: rgba(255, 255, 255, 0.55);
  --project-list-table-progressbar-border-color: rgba(255, 255, 255, 0.55);
  --projectlist-member-avatar-border-color: #ffffff;
  --projectlist-member-null-avatar-bg-color: rgba(255, 255, 255, 0.2);
  --projectlist-member-null-avatar-icon-color: rgba(255, 255, 255);
  --table-actions-menu-icon-color: #ffffff;

  //Task list table
  --task-list-table-header-text-color: #ffffff;
  --task-list-table-bg-color: #181b1f;
  --task-list-table-data-text-color: #ffffff;
  --task-list-table-progress-color: rgba(255, 255, 255, 0.3);
  --task-list-table-progress-percents-color: #ffffff;
  --task-list-table-progress-bar-color: rgba(255, 255, 255, 0.55);
  --task-list-table-progressbar-border-color: rgba(255, 255, 255, 0.55);
  --tasklist-input-border-color: #ffffff;
  --tasklist-widget_input-border-color: rgb(93, 93, 93);
  --tasklist-widget-button-color: #0728c8;

  // Task widget
  --task-widget_button-primary-color: #0728c8;

  // Skeketon
  --skeleton-bg-primary: #d4d4d41c;

  // Menu sepertors
  --menu-seperator-primary: rgba(255, 255, 255, 0.1);

  // SettingPopup
  --MuiAutocomplete-input-text: #ffffff;
  --form-control-text-color: #ffffff;
}

[data-theme="light"] {
  --primary: #ffffff;
  --text-white: #373951;
  --left-side-bar-backgorund: #043087;
  --left-side-bar-menu-icons-color: #ffffff;
  --sidebar-back-icon: #043087;
  --left-side-bar-menu-link-text-color: #ffffff;
  --back-button-icon-hove: #284d98;
  --left-side-bar-menu-poupup-bg-color: #ffffff;
  --header-color: #b9b9b9;
  --widget-bg-primary: white;
  --widget-window_header-title-color: #284d98;
  // --back-button-icon-hove: #7c7c9b;

  --timetrack_rim-outer_bg-color: #043087;
  --timetrack_rim-inner_bg-color-stop: #aa1d29;
  --timetrack_rim-inner_bg-color-running: #4baa03;
  --timetrack_rim-inner_bg-color-idle: #f7ac29;
  --timetrack_rim-inner_bg-color-offline: #fc5426;
  --autocomplete-selector_lable-color: #373951;
  --autocomplete-selector_border-color: #b9b9b9;
  --widget-window_header_icon-color: #b9b9b9;
  --project-widget_input-border-color: #b9b9b9;
  --autocomplete-selector_lable-bg-color: white;
  --project-widget_input-lable-bg-color: white;
  --autocomplete-selector_ipnut-text-color: #000000;
  --project-add-button: #043087;
  --free-style-icon-color: #043087;
  --calender-icon: #b9b9b9;
  --widget-left-menu-bg-color: #043087;
  --timetrack_menu-items-container-color: white;
  --project-detail-progress-bar: linear-gradient(
    to Left,
    #4779df,
    #2857b5,
    #053087
  );
  --project-detail-cirlce: linear-gradient(to top, #113a8d, #113a8d, #113a8d);
  --progressbar-color-bg: #f5f5f5;
  --task-list-table-bg-color: #ffffff;
  --border-color: #dddddd;
  --profile-widget-hover-color: #aabeff;
  --profile-button: #b9b9b9;
  --profile-widget-card-color: white;
  --profile-widget-circle-color: #b9b9b9;
  --background-color-card: white;
  --color: red;
  --notification-tab-color-dark: #ffffff;
  --notification-tab-color-light: #d1d9f2;
  --notification-text-header: #1c3988;
  --assign-member-popup: #ffffff;
  --assign-member-popup-border-color: #dddddd;
  --scrollbar-thumb-color: rgba(255, 255, 255, 0.15);
  --top-menu-lable-bg: #ffffff;
  --copy-button-bg: #043087;
  --link-icon: #043087;
  --setting-switch-button-off: #abb2c9;
  --setting-switch-button-on: #043087;
  --setting-lable-dot-color: white;
  --setting-content-color: #ededed;
  --assign-member-lable: white;
  --autocomplete-selector_lable-bg-profile-color: white;
  // SettingPopup
  --MuiAutocomplete-input-text: black;
  --Mui-button-cancel-button: black;
  --mui-radio-button-color-MuiButtonBase-root: black;
  --time-tracker-inner-contain-container-text-color: black;
  --form-control-text-color: black;
  --left-side-bar-menu-link-text-hover-color: #284d98;
  --card-background-color: white;
  --autoComplete-color: rgb(5, 5, 5);
  --autoComplete-color-border: #b9b9b9;
  --autoComplete-bg-color: #363a45;
  --team-view-card-bg-color: white;
  --team-view-card-border-color: white;
  --iconbg-color-view-team: #f4f4f4;
  --delete-popup-color: white;

  //Team FirstName WidgetDetail

  --project-detail-progress-bar-team: linear-gradient(
    to Left,
    #02ffff,
    #01abda,
    #004aaf
  );
}
